import {
  borderRadius,
  borderStroke,
  colorTokensThemed,
  controlBorderFocus,
  controlShadowFocus,
  primaryColor,
  problemColor,
  secondaryColor,
  spacing,
  stripeControlBorder,
  useSkin,
} from '@orus.eu/pharaoh'
import type { Appearance } from '@stripe/stripe-js'
import { useMemo } from 'react'
/**
 * Stripe Elements are rendered in their own iframe context, which means they don't have access
 * to our CSS variables defined at :root level in our main application. To work around this,
 * we need to:
 *
 * 1. Extract all CSS variables from :root by scanning document.styleSheets
 * 2. Resolve any nested CSS variable references (e.g. var(--another-var))
 * 3. Format them to match our colorTokens type
 * 4. Pass the resolved color values to Stripe's appearance API
 */

const getCSSRootVariables = () => {
  const cssVars: Record<string, string> = {}

  for (const sheet of document.styleSheets) {
    if (!sheet.href || sheet.href.startsWith(window.location.origin)) {
      for (const rule of sheet.cssRules) {
        if (rule instanceof CSSStyleRule && rule.selectorText === ':root') {
          for (const style of rule.style) {
            if (style.startsWith('--')) {
              cssVars[style] = rule.style.getPropertyValue(style).trim()
            }
          }
        }
      }
    }
  }

  return cssVars
}

export function useStripeAppearance(): Appearance {
  const skin = useSkin()
  const { fontFamily } = skin.stripe

  // Get all CSS variables from :root
  const myColorTokens = useMemo(() => getCSSRootVariables(), [])

  // Resolve any nested CSS variable references
  const resolvedColorTokens = useMemo(() => {
    return Object.entries(myColorTokens).reduce(
      (acc, [key, value]) => {
        acc[key] = value.startsWith('var(') ? acc[value.replace('var(', '').replace(')', '')] : value
        return acc
      },
      {} as Record<string, string>,
    )
  }, [myColorTokens])

  // Format to match our colorTokens type by removing '--' prefix
  const formattedColorTokens = Object.fromEntries(
    Object.entries(resolvedColorTokens).map(([key, value]) => [key.replace('--', ''), value]),
  ) as Record<keyof (typeof colorTokensThemed.colorTokens)['light'], string>

  const getStripeStyle = useMemo(() => {
    return (colorTokens: Record<keyof (typeof colorTokensThemed.colorTokens)['light'], string>): Appearance => {
      return {
        theme: 'stripe',
        variables: {
          fontFamily: fontFamily,
          fontSizeBase: '16px',
          fontSizeSm: '14px',
          fontWeightNormal: '600',
          colorPrimary: colorTokens['color-fg-decorative'],
          colorText: colorTokens['color-fg-decorative'],
          colorTextSecondary: colorTokens['color-fg-decorative'],
          borderRadius: borderRadius[40],
          focusBoxShadow: controlShadowFocus(colorTokens),
          spacingGridColumn: spacing[60],
          spacingGridRow: spacing[60],
          colorIconTabSelected: colorTokens['color-bg-base-normal'],
        },
        rules: {
          '.Input': {
            border: stripeControlBorder(colorTokens),
            borderRadius: spacing[30],
            padding: `14px ${spacing[30]}`,
            height: '48px',
            fontWeight: '400',
          },
          '.Input:focus': {
            border: controlBorderFocus(colorTokens),
            outline: 'none',
            boxShadow: controlShadowFocus(colorTokens),
          },
          '.Input--invalid': {
            color: problemColor,
          },
          '.PaymentMethodSelector': {
            backgroundColor: colorTokens['color-bg-base-normal'],
            maxWidth: '400px',
          },
          '.Tab': {
            marginLeft: '16px',
            fontSize: '14px',
            fontWeight: '600',
            backgroundColor: colorTokens['color-bg-base-normal'],
            color: primaryColor,
            border: stripeControlBorder(colorTokens),
          },
          '.Tab:hover': {
            backgroundColor: colorTokens['color-bg-base-hover'],
          },
          '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
            border: `${borderStroke[20]} solid ${secondaryColor}`,
            color: colorTokens['color-bg-base-normal'],
            backgroundColor: secondaryColor,
          },
          '.TermsText': {
            fontSize: '14px',
            fontWeight: '500',
            lineHeight: '20px',
            paragraphSpacing: '12px',
          },
          '.Label': {
            fontWeight: '500',
          },
          '.p-LinkAutofillPrompt': {
            fontWeight: '500',
          },
        },
        disableAnimations: true,
      }
    }
  }, [fontFamily])

  return useMemo(() => getStripeStyle(formattedColorTokens), [getStripeStyle, formattedColorTokens])
}
